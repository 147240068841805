.dashboard-row {
    display: flex;
    flex-flow: row wrap;
}

.dashboard-row > div {
    flex-basis: 30%;
    margin: 1rem;
    flex-grow: 1;
    min-width: 250px;
}

.dashboard-illustration-wrapper {
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
}

.dashboard-illustration {
    max-width: 200px;
}

@media only screen and (max-width: 768px) {
    .dashboard-row > div {
        margin: 0;
        margin-bottom: 1rem;
        min-width: 100%;
    }
}
