.cleaningInfo-content .page-content {
    width: 90%;
    margin-left: auto;
    margin-right: 0;
}

.cleaningInfo-layout {
    display: flex;
}

.cleaningInfo-layout .text {
    flex-basis: 50%;
    min-width: 480px;
    padding-right: var(--margin-large);
}

.cleaningInfo-layout .illustration img {
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .cleaningInfo-content .page-content {
        margin: 0 auto;
    }
    .cleaningInfo-layout .illustration {
        display: none;
    }
    .cleaningInfo-layout .text {
        width: 100%;
        min-width: unset;
    }
}
