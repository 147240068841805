.location-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.location-item:last-child {
    margin-bottom: 0;
}

.location-item-content-wrapper {
    cursor: pointer;
    display: flex;
    word-break: break-word;
}

.location-item-content p {
    margin-bottom: 0;
}

.location-item-actions {
    display: flex;
}

.location-item-actions button {
    align-self: center;
    margin-right: .5rem;
}
.location-item-actions button:last-child {
    align-self: center;
    margin-right: 0;
}
