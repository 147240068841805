.workorder-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.workorder-item:last-child {
    margin-bottom: 0;
}

.workorder-item-content-wrapper {
    cursor: pointer;
    display: flex;
    word-break: break-word;
}

.workorder-from-avatar {
    align-self: center;
    background-color: gray;
    border-radius: 100%;
    flex-shrink: 0;
    height: 40px;
    margin-right: var(--margin-medium);
    width: 40px;
}

.workorder-item-content p {
    margin-bottom: 0;
}

.workorder-item-actions {
    display: flex;
}

.workorder-item-actions button {
    align-self: center;
    margin-right: .5rem;
}
.workorder-item-actions button:last-child {
    align-self: center;
    margin-right: 0;
}
