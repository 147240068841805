.desktop-nav {
  flex-basis: 20%;
  max-width: 300px;
  flex-grow: 0;
  flex-shrink: 0;
}

.desktop-nav-logo {
    width: 95%;
    margin: 1rem 0;
}

.desktop-nav-logo > img {
    display: block;
    width: 100%;
}

.desktop-navigation-items {
    padding-left: 1rem;
}

.desktop-nav .list-group-item,
.mobile-nav .list-group-item {
    color: #fff !important;
    border: none;
    background-color: transparent !important;
    margin-bottom: 1rem;
    border-top-left-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.desktop-nav .list-group-item img {
  width: 25px;
  margin-right: var(--margin-medium);
}

.desktop-nav .list-group-item-action:hover {
  cursor:pointer;
  color:var(--secondary-color) !important;
  border-top-left-radius:15px;
  border-bottom-left-radius:15px;
  background: linear-gradient(to right, rgba(255,255,255,0.7) 0%,rgba(255,255,255,0) 90%);
}

.desktop-nav .list-group-item.active,
.mobile-nav .list-group-item.active {
    background-color: #fff !important;
    color: var(--secondary-color) !important;
}

.desktop-nav .list-group-item.active:after,
.desktop-nav .list-group-item.active:before {
  content:'';
  position:absolute;
  background:rgba(11,129,197,1);
  right:0px;
  width:15px;
  height:15px;
  box-shadow:5px 5px 0px 0px #fff;
}
.desktop-nav .list-group-item.active:after {
  top:-15px;
  border-bottom-right-radius:15px;
  box-shadow:5px 5px 0px 0px #fff;}
.desktop-nav .list-group-item.active:before {
  bottom:-15px;
  border-top-right-radius:15px;
  box-shadow:5px -5px 0px 0px #fff;
}

/* Mobile navigation */
.mobile-nav {
  display: none;
}

.mobile-nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1.5rem;
}

.mobile-nav-logo {
  flex-basis: 40%;
  flex-grow: 0;
}

.mobile-nav-logo > img {
  width: 100%;
  display: block;
}

.mobile-nav-toggle {
  background-color: var(--primary-blue);
  border-color:#fff !important;
}

.mobile-nav-toggle:hover,
.mobile-nav-toggle:focus {
  background-color: var(--primary-blue-darken);
}

.mobile-nav-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 255, 255,.3);
}

.mobile-nav-menu {
  background-color: var(--primary-blue);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  overflow-y: auto;
  transform: translateX(-100%);
  transition: transform .2s;
}

.mobile-nav-menu.active {
  transform: translateX(0);
}

.mobile-navigation-items-header {
    display: flex;
    justify-content: flex-end;
}

.mobile-navigation-items-header > .btn {
    border: none;
    margin: .5rem;
}


.mobile-nav .list-group-item {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-bottom: 0;
  padding: 1rem 1.25rem;
}

@media only screen and (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .mobile-nav {
    display: block;
  }
}

