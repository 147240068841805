:root {
  --primary-blue: #0B81C5;
  --primary-green: #62CA3C;
  --primary-blue-darken: #126da2;
  --secondary-color: #F4610B;
  --margin-large: 3rem;
  --margin-medium: 1.5rem;
}

* {
  font-family: 'Poppins', sans-serif;
  /* color: #302E2F; */
}

html,body,nav {
  height: 100%;
}

#root,.row {
  height: 100%
}

.app-wrapper {
  display: flex;
  min-height: 100vh;
}

.app-content {
  flex-grow: 1;
}

.modal-body {
  min-height: 100px;
}

img {
  max-width: 100%;
  height: auto;
}

h2, h3, h6 {
  color: var(--primary-blue);
  font-weight: 600;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 22px;
}

p, a {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5rem;
}

a {
  color: var(--primary-blue);
  text-decoration: underline;
}

a.highlight {
  color: var(--secondary-color);
}

.vertical-center {
  display: flex;
  align-items: center;
}

.bg-primary {
  background-color: var(--primary-blue) !important;
}

/* Override Bootstrap buttons */

.btn-primary {
  background-color: #fff !important;
  color: #0D7D9F !important;
  font-style: italic;
  padding: .5rem 1.5rem;
  border: 2px solid var(--secondary-color);
  border-radius: 25px;
}
.btn-primary:hover {
  color: #fff !important;
  background-color: var(--primary-blue) !important;
  border: 2px solid var(--secondary-color) !important;
}

.mtb-logo {
  max-width: 120px;
  margin-bottom: var(--margin-large);
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: auto;
  align-content: space-between;
  min-height: 600px;
  height: 100%;
}

.item {
  flex: 0 0 auto;
  margin: 10px;
  height: 50%;
  width: 30%;
  border: 1px solid grey;
}

.white-text{
  color: #fff;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.ql-editor {
  min-height: 200px;
}

* {font-family: "Poppins",sans-serif;}


.button {
  border:1px solid #ccc;border-radius:5px;display:inline-block;
  padding:10px 15px;margin:5px;transition:0.2s;
  font-size:1.2em;color:#333;font-weight:400;vertical-align:middle;
}
.button svg {
  width: 1em;vertical-align:bottom;padding-left:0.3em;
}

.button:hover {
  cursor:pointer;border:1px solid #333;
}

.accordionItem h3 {color:#0b81c5;margin-bottom:0px;}
.accordionItem p.empty {
  color:#666;margin-top:0px;padding-left:10px;
  font-weight:thin;
  }
  
.accordionItem .listItemContainer {
  position: relative;
  display: block;
  margin:10px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
}
  
.accordionItem .listItemContainer .listItem {
  position: relative;
  display: block;text-decoration:none;
  padding: .75rem 1.25rem;
  border-top: 1px solid rgba(0,0,0,.125);
}
.accordionItem .listItemContainer .listItem:hover {
  text-decoration:underline;
}
  
.accordionItem .listItemContainer .listItem:first-child {
  border-top: none;
}
  
.accordionItem .listItemContainer .listItem.image {
  display:inline-block;border:none;border-radius:2px;
  width:25%;padding:0px;margin:1%;position:relative;
  box-shadow:1px 1px 3px rgba(0,0,0,0.2);overflow:hidden;
}
  
.accordionItem .listItemContainer .listItem.image img {
  width:100%;height:auto;margin:0px;padding:0px;float:left;
}
  
.accordionItem .listItemContainer .listItem.image:after {
  content:'+';background:rgba(0,0,0,0.2);font-size:30px;color:#fff;
  width:100%;height:100%;position:absolute;top:0px;left:0px;
  padding-left:10px;font-size:30px;color:#fff;
  opacity:0;pointer-events:none;transition:0.2s;
}
  
.accordionItem .listItemContainer .listItem.image:hover:after {
  opacity:1;
}

/* MTB custom styleset 18.12.2020 */

/* edit/remove buttons */
.news-item-actions {display:block;}
button.btn.btn-sm {color:#fff;margin-bottom:5px;border:none;border-radius:100%;box-shadow:1px 1px 0px rgba(0,0,0,0.5);transition:0.2s;}
button.btn.btn-sm:hover {box-shadow:1px 1px 0px rgba(0,0,0,1);}
​
button.btn.btn-secondary.btn-sm {background:#0b81c5;}
button.btn.btn-warning.btn-sm {background:orangered;}

/* basic UI elements */
.location-item-content h6 {font-size: 22px;}
.location-item-content p {margin-left: 10px;}

.accordionItem, .mt-5 {margin-bottom: 25px;}
.accordionItem .listItemContainer .listItem:hover {text-decoration:none;}

.listItem.text img {display:none;}
.listItem.text.list-group-item {border:none;padding:0px;margin:0px;}
.listItem.text a {border:1px solid rgba(0,0,0,.125);display:block;padding:.75rem 1.25rem;border-bottom:none;}
.listItem.text:last-child a {border-bottom:1px solid rgba(0,0,0,.125);border-bottom-left-radius:5px;border-bottom-right-radius:5px;transition:0.1s}
.listItem.text:first-child a {border-top-left-radius:5px;border-top-right-radius:5px;}
.listItem.text a:hover {background:#f4f4f4;}

p.news-item-description img {margin: 25px auto;}

/* App.css:187 - ylimääräinen border pois, jotta tyhjät kohdat erottuu paremmin ja selkeämmin. Oikeastaan näitä tyhjiä ei tarvitsi tulostua ollenkaan jos ovat tyhjiä - mutta tämäkin käy */
.accordionItem .listItemContainer {
	position: relative;
	display: block;
	margin: 10px;
	background-color: #fff;
	border: none;
}
/* input:select button tyyleillä */
select.form-control {
	padding: 10px 15px;
	margin: 5px;
	transition: .2s;
	font-size: 1.2em;
	height: auto;
}
/* avatardefault viesteihin - lataa kuva lokaaliin ja vaihda toki URL */
.message-from-avatar{
	background-image: url('assets/images/avatar.png');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	border-radius: 100%;
	box-shadow: 1px 1px rgba(0,0,0,0.2);
}
/* Listojen reunatyylit (viestit ja asiakkaat) */
.message-item, .customer-item {
	border-top: 1px solid #ccc;
	padding-top: 1rem;
}
.message-item:last-child, .customer-item:last-child {
	border-bottom: 1px solid #ccc;
	padding-bottom: 1rem;
}
/* viestin otsikko kohdalleen */
.message-item-content h6, .customer-item h6 {
	line-height: 35px;
}

/* TARGET MOBILE */
@media only screen and (max-width: 768px) {
  /* anchor adding buttons to bottom of the screen */
  /* .buttonContainer {
      position: fixed;
      bottom: 0px;
      left: 0px;
      width: 100%;
  }
  .buttonContainer .vertical-center {
      width: 50%;
      margin: 0%;
  }
  .buttonContainer .vertical-center .button {
      margin: 1% 1% !important;
      width: 98%;
      line-height: 200%;
      position:relative;
      background:#fff;
  }
  .buttonContainer .vertical-center .button svg.svg-inline--fa {
      position: absolute;
      right: 20px;
      top: 8px;
  }
  .page-wrapper {
      padding-bottom: 100px!important;
  } */
  /* General font sizes on each element */
  select.form-control {
      font-size: 0.8em;
      width: 90%;
  }
  h2 {font-size: 25px!important;}
  h6, h3 {font-size: 20px!important;}
  p {font-size: 15px!important;}
  .button {font-size: 16px;}
  }

  @media only screen and (max-width: 768px) {
    /* adjust text sizes etc minors */
    h3 {font-size: 20px!important;}
    h6 {font-size: 18px!important;}
    .cleaningInfo-layout .text {flex-basis: 100%;}
    }


    @media only screen and (max-width: 768px) {
      /* NEWS QUICKFIX FOR MOBILE ONLY */
      .app-wrapper {display: block;}
      .news-layout .text {display: block;width: 100%;}
      .news-layout .news-item {display: block;width: 100%;}
      .news-item-content-wrapper {display: block!important;}
      .news-layout iframe {width: 100%;}
      }


/* separate news from each other */
.news-item-content-wrapper {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ccc;
  }
  /* TABLET QUICKFIX */
  @media only screen and (max-width: 1130px) and (min-width: 768px) {
  .news-content .page-content {margin: 0px!important;width: 100%!important;}
  .news-content iframe {width: 100%;}
  .news-item-content-wrapper {display: block!important;}
  }