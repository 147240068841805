.profile-content .page-content {
    width: 90%;
    margin-left: auto;
    margin-right: 0;
}

.profile-layout {
    display: flex;
}

.profile-layout .text {
    flex-basis: 60%;
    min-width: 600px;
    padding-right: var(--margin-large);
}

@media only screen and (max-width: 768px) {
    .profile-content .page-content {
        margin: 0 auto;
    }
    .profile-layout .text {
        width: 100%;
        min-width: unset;
    }
}
