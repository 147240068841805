.page-wrapper {
  position: relative;
    width: 100%;
    padding: var(--margin-large);
  }

.page-header {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.page-content.center {
    max-width: 900px;
    margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .page-wrapper {
    padding: var(--margin-large) 0;
  }

  .page-content {
    width: 90%;
    margin: 0 auto;
  }
}