.news-content .page-content {
    width: 90%;
    margin-left: auto;
    margin-right: 0;
}

.news-layout {
    display: flex;
}

.news-layout .text {
    padding-right: var(--margin-large);
}
