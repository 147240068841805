.news-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--margin-medium);
    margin-right: 5%;
}
.news-item:last-child {
    margin-bottom: 0;
}

.news-item-content-wrapper {
    cursor: pointer;
    display: flex;
    word-break: break-word;
}

.news-item-image {
    flex-shrink: 0;
    margin-right: var(--margin-medium);
    width: 240px;
}
.news-item-image > img {
    width: 100%;
}

.news-item-thumbnail {
    flex-shrink: 0;
    margin-right: var(--margin-medium);
    width: 80px;
}
.news-item-thumbnail > img {
    width: 100%;
}

.news-item-content p {
    margin-bottom: 0;
}

.news-item-actions {
    display: flex;
}

.news-item-actions button {
    align-self: center;
    margin-right: .5rem;
}
.news-item-actions button:last-child {
    align-self: center;
    margin-right: 0;
}
