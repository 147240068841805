.locations-list-content .page-content {
    width: 90%;
    margin-left: auto;
    margin-right: 0;
}

.locations-list-layout {
    display: flex;
}

.locations-list-layout .text {
    flex-basis: 50%;
    min-width: 320px;
    padding-right: var(--margin-large);
}

.locations-list-layout .illustration img {
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .locations-list-content .page-content {
        margin: 0 auto;
    }

    .locations-list-layout .illustration {
        display: none;
    }
    .locations-list-layout .text {
        min-width: unset;
        flex-basis: 100%;
        padding: 0;
    }
}
