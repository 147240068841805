.login-screen-header {
    color: #fff;
    padding: 1rem;
}

.login-center {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 30%;
    flex-grow: 0;
    min-width: 300px;
    text-align: center;
}

.login-screen-header > img {
    width: 300px;
}

.login-screen-wrapper {
    display: flex;
    flex-flow: column;
    min-height: 100%;
}

.login-screen-wrapper {
    display: flex;
}

.login-screen-content {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    margin-bottom: var(--margin-large);
}

.login-illustration {
    flex-basis: 30%;
    display: block;
    padding-top: 6rem;
    display: flex;
    align-items: flex-end;
}

.login-illustration.left {
    justify-content: flex-end;
    padding-right: 5.5rem;
}

.login-illustration.right {
    justify-content: flex-start;
    padding-left: 2rem;
}

.login-illustration > img {
    max-width: 100%;
}

@media only screen and (max-width: 768px) {
    .login-illustration.left,
    .login-illustration.right {
        display: none;
    }

    .login-center {
        flex-basis: 90%;
        min-width: unset;
    }
}
