.border {
    border: 1px solid #979797;
}

.dashboard-card {
    border-radius: 1rem;
    height: 100%;
    margin-bottom: var(--margin-medium);
    padding: 1rem;
    width: 100%;
}

.dashboard-card-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--margin-large);
    padding: .25rem .25rem 0;
}

.dashboard-card-header-icon {
    color: var(--primary-green) !important;
    font-size: 24px;
}

.dashboard-card-header-icon:hover {
    cursor: pointer;
}

.dashboard-card-header h3 {
    margin: 0;
}
